<template>
  <div class="dashboard-widget-template" style="padding:5px; position: relative; height: 100%" v-loading="loadingWidgetResult" >
    <component
      :is="chartViewTemplate"
      :key="renderIndex"
      :settings="settings"
      :data="data"
      :result="widgetResult"
    ></component>
  </div>
</template>

<script>
import ReportResult from "@/components/report/builder/ReportResult.vue";
import {Chart as highcharts} from "highcharts-vue";
import {getChartWidgetTemplate} from "@/components/home/dashboard/widgets/VQBChartWidget/ChartWidgetHelper";
import {WidgetViewMixin} from "@/components/home/dashboard/widgets/WidgetViewMixin";

export default {
  name: 'VQBChartWidgetTemplate',
  mixins: [WidgetViewMixin],
  components: {highcharts, ReportResult},
  props: {
    widget: {type: Object},
    data: {type: Object},
  },

  beforeMount() {
    if( !this.data.colors ){
      let colors = this.$companyConfiguration.home.getColors();
      if( !colors || !colors.length ){
        colors = [ "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1" ];
      }
      let colorsObjects = [];
      colors.forEach(color => {
        colorsObjects.push({value: color});
      })

      this.data.colors = colorsObjects;
    }
  },
  mounted() {
    this.loadWidgetResult();
  },
  computed: {
    chartViewTemplate() {
      return getChartWidgetTemplate(this.data.type, 'view');
    },
  },
  data(){
    return {
      renderIndex: 1,
    }
  },
  methods: {
    eventWidgetResized(){
      this.renderIndex++;
    },
  }
}
</script>