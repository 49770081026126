const getChartWidgetTemplate = (chartType, templateType) => {
    switch (chartType){
        case 'line':
        case 'area':
        case 'column':
        case 'bar':
            return () => import(`./charts/line/${templateType}`);
        case 'pie':
            return () => import(`./charts/pie/${templateType}`);
        default:
            return null;
    }
}

export { getChartWidgetTemplate }